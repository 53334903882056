<style scoped>
.label-checkbox {
	padding: 2px 0 0 0;
}

.a-border-bottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
<template>
	<b-row :class="`${!end ? 'py-2 a-border-bottom' : 'py-2'}`">
		<b-row class="ml-0 py-2" style="width: 100%">
			<b-col cols="10">
				<span>
					{{ item.msg }}
				</span>
			</b-col>
			<b-col cols="2">
				<b-form-checkbox class="pull-right" size="md" v-model="item.checked" @change="toggle">
					<div class="label-checkbox">
						{{ item.checked ? this.FormMSG(1, 'Yes') : this.FormMSG(2, 'No') + '&nbsp;' }}
					</div>
				</b-form-checkbox>
			</b-col>
		</b-row>
		<b-row class="col-12" v-if="subItems.length > 0">
			<b-col cols="12" class="ml-3" v-if="item.checked">
				<b-card class="rounded-0">
					<SectionSubSubQuiz
						v-for="(subItem, i) in subItems"
						:key="subItem.value"
						:isParentUnchecked="item.checked"
						:item="subItem"
						:end="subItems.length - 1 === i"
					/>
				</b-card>
			</b-col>
		</b-row>
	</b-row>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import SectionSubSubQuiz from '@/views/albert/albertactionplan/SectionSubSubQuiz';
import { getDeepTree, updatedAlbertAction } from '@/cruds/albertactionplan.crud';

export default {
	name: 'AlbertActionPlanSectionSubQuiz',
	mixins: [languageMessages],
	components: {
		SectionSubSubQuiz
	},

	props: {
		item: {
			type: Object,
			require: true,
			default: null
		},
		end: {
			type: Boolean,
			require: true,
			default: false
		},
		quiz: {
			type: Array,
			require: true,
			default: () => []
		},
		subItems: {
			type: Array,
			require: true,
			default: () => []
		},
		isParentUnchecked: {
			type: Boolean,
			require: true,
			default: false
		}
	},

	methods: {
		getAlbertSubActionsById(id, parent = 0) {
			return getDeepTree(this.quiz, id, parent);
		},

		async toggle(payload) {
			this.item.checked = payload;
			const item = await this.update(this.item);
			this.item.id = item.id;
		},

		async update(item) {
			return await updatedAlbertAction(item);
		}
	},

	watch: {
		isParentUnchecked: {
			handler: function (n) {
				// console.log('subquiz: ', n);
				if (n !== undefined && !n) {
					this.item.checked = n;
					this.$emit('update', this.item);
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>

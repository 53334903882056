<style scoped>
.label-checkbox {
	padding: 2px 0 0 0;
}

.a-border-bottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
<template>
	<b-row :class="`${!end ? 'px-4 py-0 a-border-bottom' : 'px-4 py-0'}`">
		<b-row class="ml-0 py-3" style="width: 100%">
			<b-col cols="10">
				<span class="font-weight-bold text-secondary">
					{{ item.msg }}
				</span>
			</b-col>
			<b-col cols="2">
				<b-form-checkbox class="pull-right" size="md" v-model="item.checked" @change="toggle">
					<div class="text-secondary font-weight-bold label-checkbox">
						{{ item.checked ? this.FormMSG(1, 'Yes') : this.FormMSG(2, 'No') + '&nbsp;' }}
					</div>
				</b-form-checkbox>
			</b-col>
		</b-row>
		<b-row class="col-12" v-if="subItems.length > 0">
			<b-col cols="12" class="ml-3" v-if="item.checked">
				<b-card class="rounded-0">
					<SectionSubQuiz
						v-for="(subItem, i) in subItems"
						:key="subItem.value"
						:item="subItem"
						:isParentUnchecked="item.checked"
						:end="subItems.length - 1 === i"
						:subItems="getAlbertSubActionsById(menuId, subItem.value)"
						:quiz="quiz"
					/>
				</b-card>
			</b-col>
		</b-row>
	</b-row>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import SectionSubQuiz from '@/views/albert/albertactionplan/SectionSubQuiz';
import { getDeepTree, updatedAlbertAction } from '@/cruds/albertactionplan.crud';

export default {
	name: 'AlbertActionPlanSectionQuiz',
	mixins: [languageMessages],

	components: {
		SectionSubQuiz
	},

	props: {
		item: {
			type: Object,
			require: true,
			default: null
		},
		end: {
			type: Boolean,
			require: true,
			default: false
		},
		quiz: {
			type: Array,
			require: true,
			default: () => []
		},
		subItems: {
			type: Array,
			require: true,
			default: () => []
		},
		menuId: {
			type: Number,
			require: true,
			default: 0
		}
	},

	methods: {
		getAlbertSubActionsById(id, parent = 0) {
			return getDeepTree(this.quiz, id, parent);
		},

		async toggle(payload) {
			this.item.checked = payload;
			const item = await this.update(this.item);
			this.item.id = item.id;
		},

		async update(item) {
			return await updatedAlbertAction(item);
		}
	}
};
</script>

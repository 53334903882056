var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { class: `${!_vm.end ? "py-2 ass-border-bottom" : "py-2"}` },
    [
      _c("b-col", { attrs: { cols: "10" } }, [
        _c("span", [_vm._v("\n\t\t\t" + _vm._s(_vm.item.msg) + "\n\t\t")]),
      ]),
      _c(
        "b-col",
        { attrs: { cols: "2" } },
        [
          _c(
            "b-form-checkbox",
            {
              staticClass: "pull-right",
              attrs: { size: "md" },
              on: { change: _vm.toggle },
              model: {
                value: _vm.item.checked,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "checked", $$v)
                },
                expression: "item.checked",
              },
            },
            [
              _c("div", { staticClass: "label-checkbox" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      _vm.item.checked
                        ? this.FormMSG(1, "Yes")
                        : this.FormMSG(2, "No") + " "
                    ) +
                    "\n\t\t\t"
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<style scoped>
.big-menu {
	padding: 0 16px 0 0;
}

.small-menu {
	padding: 0 5px 0 0;
}

.label-checkbox {
	padding: 2px 0 0 0;
}

.a-border-bottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row>
			<b-col :cols="`${$screen.width >= 992 ? 8 : 10}`" :class="`${$screen.width >= 992 ? 'my-1' : 'my-2'}`">
				<h4>{{ selectedMenu ? selectedMenu.text : '' }}</h4>
			</b-col>
			<b-col :cols="`${$screen.width >= 992 ? 4 : 2}`">
				<b-row :class="`${$screen.width >= 992 ? 'pull-right big-menu' : 'pull-right small-menu'}`">
					<SectionMenu :menus="menus" />
				</b-row>
			</b-col>
		</b-row>
		<b-row v-if="menuId === 1314">
			<b-col cols="12">
				<p></p>
				<p>
					{{
						this.FormMSG(
							4,
							`To successfully complete a carbon action plan in the UK your production must be carbon neutral. If your production has not been commissioned in the UK you can still complete the carbon action plan and you can chose whether to offset the remaining emissions.`
						)
					}}
				</p>
				<p>
					{{
						this.FormMSG(
							2,
							`To achieve carbon neutrality you should decarbonise your production as much as possible through choosing renewable energy, electric transport etc. and then you will need to offset the rest. You can offset through albert or through another reputable offsetting project. Visit our offsetting page for more info.`
						)
					}}
				</p>
				<p>
					{{
						this.FormMSG(
							3,
							`Offsetting typically costs 0.1% of a production's budget but it's important that you complete a draft footprint so you know how much to budget for offsetting and the areas in which you might be able to reduce your impact.`
						)
					}}
				</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12">
				<b-card>
					<SectionQuiz
						v-for="(item, i) in items"
						:key="item.value"
						:item="item"
						:end="items.length - 1 === i"
						:subItems="getAlbertSubActionsById(menuId, item.value)"
						:quiz="quiz"
						:menuId="menuId"
					/>
				</b-card>
			</b-col>
		</b-row>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import SectionMenu from '@/views/albert/albertactionplan/SectionMenu';
import SectionQuiz from '@/views/albert/albertactionplan/SectionQuiz';
import { getAlbertActions, getDeepTree } from '@/cruds/albertactionplan.crud';
import { isNil } from '@/shared/utils';

export default {
	name: 'AlbertActionPlanSection',
	mixins: [languageMessages],

	components: {
		SectionMenu,
		SectionQuiz
	},

	computed: {
		menus() {
			const menus = this.FormMenu(1305);

			return menus.length === 1 && menus[0].value === 0 && menus[0].text === 'not found' ? [] : menus;
		}
	},

	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			isLoading: false,
			selectedMenu: null,
			menuId: 0,
			quiz: [],
			items: []
		};
	},

	created() {
		this.$nextTick(async () => {
			const id = parseInt(this.$route.params.id, 10);
			const selectedMenu = this.menus.filter((menu) => menu.value === id);
			this.menuId = id;
			this.selectedMenu = selectedMenu.length > 0 ? selectedMenu[0] : null;
			await this.getAlbertActions();
			this.getAlbertActionsById(id);
		});
	},

	methods: {
		async getAlbertActions() {
			this.quiz = await getAlbertActions();
		},

		getAlbertActionsById(id, parent = 0) {
			this.items = getDeepTree(this.quiz, id, parent);
		},

		getAlbertSubActionsById(id, parent = 0) {
			return getDeepTree(this.quiz, id, parent);
		}
	},

	watch: {
		'$route.params.id': {
			handler: function (id) {
				if (!isNil(id)) {
					id = parseInt(id, 10);
					const selectedMenu = this.menus.filter((menu) => menu.value === id);
					this.menuId = id;
					this.selectedMenu = selectedMenu[0];
					this.getAlbertActionsById(id);
				}
			},
			deep: true
		}
	}
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { class: `${!_vm.end ? "px-4 py-0 a-border-bottom" : "px-4 py-0"}` },
    [
      _c(
        "b-row",
        { staticClass: "ml-0 py-3", staticStyle: { width: "100%" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("span", { staticClass: "font-weight-bold text-secondary" }, [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.item.msg) + "\n\t\t\t"),
            ]),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "pull-right",
                  attrs: { size: "md" },
                  on: { change: _vm.toggle },
                  model: {
                    value: _vm.item.checked,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "checked", $$v)
                    },
                    expression: "item.checked",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-secondary font-weight-bold label-checkbox",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.item.checked
                              ? this.FormMSG(1, "Yes")
                              : this.FormMSG(2, "No") + " "
                          ) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.subItems.length > 0
        ? _c(
            "b-row",
            { staticClass: "col-12" },
            [
              _vm.item.checked
                ? _c(
                    "b-col",
                    { staticClass: "ml-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "rounded-0" },
                        _vm._l(_vm.subItems, function (subItem, i) {
                          return _c("SectionSubQuiz", {
                            key: subItem.value,
                            attrs: {
                              item: subItem,
                              isParentUnchecked: _vm.item.checked,
                              end: _vm.subItems.length - 1 === i,
                              subItems: _vm.getAlbertSubActionsById(
                                _vm.menuId,
                                subItem.value
                              ),
                              quiz: _vm.quiz,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const getDeepTree = (items, itemId, parentId) => {
	return items.filter((item) => item.menuNumber === itemId && item.parentValue === parentId);
};

export const getDeepTreeChecked = (items, itemId, parentId) => {
	return items.filter((item) => item.menuNumber === itemId && item.parentValue === parentId && item.checked);
};

export const getAlbertActions = async () => {
	const { data } = await apollo.query({
		query: gql`
			query {
				GetAlbertActions {
					id
					projectId
					msg
					checked
					menuNumber
					parentValue
					value
				}
			}
		`,
		fetchPolicy: 'no-cache'
	});

	return data.GetAlbertActions;
};

export const updatedAlbertAction = async (item) => {
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($UpdatedAlbertAction: AlbertActionInput!) {
					AddUpdAlbertAction(UpdatedAlbertAction: $UpdatedAlbertAction) {
						id
						projectId
						msg
						checked
						menuNumber
						parentValue
						value
					}
				}
			`,
			variables: {
				UpdatedAlbertAction: item
			},
			fetchPolicy: 'no-cache'
		});

		return data.AddUpdAlbertAction;
	} catch (e) {
		console.log({ e });
	}
};

<template>
	<div v-if="menus.length > 0">
		<b-dropdown variant="outline-secondary" size="sm" :text="selectedMenu.text" v-if="$screen.width >= 992" right>
			<b-dropdown-header id="dropdown-header-label">
				{{ this.FormMSG(1, 'Switch to another section') }}
			</b-dropdown-header>
			<b-dropdown-item-button v-for="menu in menus" :key="menu.value" :active="selectedMenu.value === menu.value" action @click="changeMenu(menu)">
				{{ menu.text }}
			</b-dropdown-item-button>
		</b-dropdown>
		<b-dropdown variant="link" toggle-class="text-decoration-none" no-caret v-if="$screen.width < 992" right>
			<template #button-content>
				<b-button variant="primary" size="sm" class="mx-0">
					<i class="fa fa-bars" aria-hidden="true"></i>
				</b-button>
			</template>
			<b-dropdown-header id="dropdown-header-label">
				{{ this.FormMSG(1, 'Switch to another section') }}
			</b-dropdown-header>
			<b-dropdown-item v-for="menu in menus" :key="menu.value" :active="selectedMenu.value === menu.value" action @click="changeMenu(menu)">
				{{ menu.text }}
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { isNil } from '@/shared/utils';

export default {
	name: 'AlbertActionPlanSectionMenu',
	mixins: [languageMessages],

	props: {
		menus: {
			type: Array,
			require: true,
			default: () => []
		}
	},

	data: () => {
		return {
			selectedMenu: null
		};
	},

	created() {
		const id = parseInt(this.$route.params.id, 10);
		const selectedMenu = this.menus.filter((menu) => menu.value === id);
		this.selectedMenu = selectedMenu.length > 0 ? selectedMenu[0] : null;
	},

	methods: {
		changeMenu(menu) {
			const id = menu.value;
			this.$router.push(`/albertactionplan/${id}`);
			const selectedMenu = this.menus.filter((menu) => menu.value === id);
			this.selectedMenu = selectedMenu[0];
		}
	},

	watch: {
		menus: {
			handler: function (menus) {
				if (!isNil(menus) && menus.length > 0) {
					const id = parseInt(this.$route.params.id, 10);
					const selectedMenu = menus.filter((menu) => menu.value === id);
					this.selectedMenu = selectedMenu.length > 0 ? selectedMenu[0] : null;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>

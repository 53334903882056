<style scoped>
.label-checkbox {
	padding: 2px 0 0 0;
}

.ass-border-bottom {
	/* border-bottom: 1px solid rgba(255, 255, 255, 1); */
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ass-background-color {
	background-color: rgba(0, 0, 0, 0.035);
}
</style>
<template>
	<b-row :class="`${!end ? 'py-2 ass-border-bottom' : 'py-2'}`">
		<b-col cols="10">
			<span>
				{{ item.msg }}
			</span>
		</b-col>
		<b-col cols="2">
			<b-form-checkbox size="md" class="pull-right" v-model="item.checked" @change="toggle">
				<div class="label-checkbox">
					{{ item.checked ? this.FormMSG(1, 'Yes') : this.FormMSG(2, 'No') + '&nbsp;' }}
				</div>
			</b-form-checkbox>
		</b-col>
	</b-row>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { updatedAlbertAction } from '@/cruds/albertactionplan.crud';

export default {
	name: 'AlbertActionPlanSectionSubSubQuiz',
	mixins: [languageMessages],

	props: {
		item: {
			type: Object,
			require: true,
			default: null
		},
		end: {
			type: Boolean,
			require: true,
			default: false
		},
		isParentUnchecked: {
			type: Boolean,
			require: true,
			default: false
		}
	},

	methods: {
		async toggle(payload) {
			this.item.checked = payload;
			const item = await this.update(this.item);
			this.item.id = item.id;
		},

		async update(item) {
			return await updatedAlbertAction(item);
		}
	},

	watch: {
		isParentUnchecked: {
			handler: function (n) {
				if (n !== undefined && !n) {
					this.item.checked = n;
					this.$emit('update', this.item);
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
